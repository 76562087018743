// const supermindModels = {
//   "Zoom Out": "Zoom Out Few Shot",
//   "Zoom In": "Zoom In Few Shot",
//   Analogize: "Analogize Zero Shot",
// };

// const thinkAboutItModels = [
//   ["Zoom In Few Shot", { basic: "Zoom In", temperature: 0.7 }],
//   ["Zoom Out Few Shot", { basic: "Zoom Out", temperature: 0.7 }],
//   ["Analogize Zero Shot", { basic: "Analogize", temperature: 0.7 }],
//   ["Problem Statement Parts Zero Shot", { temperature: 0.7 }],
//   ["What Am I Missing Zero Shot", { temperature: 0.7 }],
//   ["Better Problem Statement Reformulation Zero Shot", { temperature: 0.7 }],
// ];

/**
 * Array of preset models for the "Explore Problem" option. Contains strings which
 * corresponds to the ending of the Supermind Ideator API endpoint for the specific
 * model.
 *
 * @name exploreProblemMoves
 * @type {array}
 */
const exploreProblemMoves = [
  "general.zoom_in_parts",
  "general.zoom_in_types",
  "general.zoom_out_parts",
  "general.zoom_out_types",
  "general.analogize",
  // "general.problem_statement_parts",
  "general.what_am_i_missing",
  // "general.better_problem_statement_reformulation",
];

// const findSolutionsModels = [
//   ["Groupify Few Shot", { groupify: "Market", temperature: 0.7 }],
//   ["Corpus D", { groupify: "Market", cognify: "None", temperature: 0.7 }],
//   ["Groupify Few Shot", { groupify: "Community", temperature: 0.7 }],
//   ["Corpus D", { groupify: "Community", cognify: "None", temperature: 0.7 }],
//   ["Groupify Few Shot", { groupify: "Democracy", temperature: 0.7 }],
//   ["Technify Zero Shot", { temperature: 0.7 }],
//   // ["Creative Matrix Zero Shot", { temperature: 0.7 }],
//   // ["Supermind Design Library", {}],
// ];

/**
 * Array of preset models for the "Explore Solutions" option. Contains strings which
 * corresponds to the ending of the Supermind Ideator API endpoint for the specific
 * model.
 *
 * @name exploreSolutionsMoves
 * @type {array}
 */
const exploreSolutionsMoves = [
  "groupify.market_fewshot",
  "groupify.community_fewshot",
  "groupify.democracy_fewshot",
  "technify.zeroshot",
  "general.sdl_search",
  // "general.creative_matrix",
];

// const moreChoicesModels = {
//   basic: {
//     "Zoom In": ["Zoom In Few Shot", { basic: "Zoom In" }],
//     "Zoom Out": ["Zoom Out Few Shot", { basic: "Zoom Out" }],
//     Analogize: ["Analogize Zero Shot", { basic: "Analogize" }],
//     Parts: ["Problem Statement Parts Zero Shot", {}],
//     Reformulation: ["Better Problem Statement Reformulation Zero Shot", {}],
//     "What Am I Missing": ["What Am I Missing Zero Shot", {}],
//   },
//   supermind: {
//     groupify: {
//       Market: ["Groupify Few Shot", { groupify: "Market" }],
//       Community: ["Groupify Few Shot", { groupify: "Community" }],
//       Democracy: ["Groupify Few Shot", { groupify: "Democracy" }],
//       Ecosystem: ["Groupify Few Shot", { groupify: "Ecosystem" }],
//     },
//     technify: ["Technify Zero Shot", {}],
//   },
// };

/**
 * Dictionary mapping basic, groupify, and cognify moves to their appropriate
 * move endpoints to access the Supermind Ideator API
 *
 * @name advancedMoves
 * @type {dictionary}
 */
const advancedMoves = {
  basic: {
    "Zoom In - Parts": ["general.zoom_in_parts"],
    "Zoom In - Types": ["general.zoom_in_types"],
    "Zoom Out - Parts": ["general.zoom_out_parts"],
    "Zoom Out - Types": ["general.zoom_out_types"],
    "Analogize": ["general.analogize"],
    "Reformulation": ["general.better_problem_statement_reformulation"],
    "Supermind Design Library": ["general.sdl_search"],
    "What Am I Missing": ["general.what_am_i_missing"],
  },
  groupify: {
    "Democracy": ["groupify.democracy_fewshot"],
    "Market": ["groupify.market_fewshot"],
    "Community": ["groupify.community_fewshot"],
    "Ecosystem": ["groupify.ecosystem_fewshot"],
  },
  cognify: {
    "Create": ["cognify.create_zeroshot"],
    "Sense": ["cognify.sense_zeroshot"],
    "Learn": ["cognify.learn_zeroshot"],
    "Decide": ["cognify.decide_zeroshot"],
    "Remember": ["cognify.remember_zeroshot"],
  },
};

// TODO replace modelBlurbs with API call to get move framing
const modelBlurbs = {
  "general.zoom_in_types":
    "What are different types of my problem?",
  "general.zoom_out_types":
    "What other problem is my problem a type of?",
  "general.zoom_in_parts":
    "What are different parts of my problem?",
  "general.zoom_out_parts":
    "What other problem is my problem a part of?",
  "general.analogize":
    "What are some useful analogies to your problem?",
  "general.what_am_i_missing":
    "What are you missing from your current problem statement?",
  "general.problem_statement_parts":
    "What subactivities need to be executed in order to solve your problem?",
  "general.better_problem_statement_reformulation":
    "What are alternative ways to formulate your problem statement?",
  "general.creative_matrix":
    "What ideas could be emerge by combining some degree of technologies and some degree of communities?",
  "general.sdl_search":
    "Here is an idea from a database of innovative Superminds",
  "general.zoom_in_fewshot":
    "What is a more specific way of thinking about your problem?",
  "general.zoom_out_fewshot":
    "What is a more general way of thinking about your problem?",
    // below here for old backend, above for new API (to be replaced)
  "Zoom In Few Shot":
    "What is a more specific way of thinking about your problem?",
  "Zoom Out Few Shot":
    "What is a more general way of thinking about your problem?",
  "Analogize Zero Shot": "What are some useful analogies to your problem?",
  "What Am I Missing Zero Shot":
    "What are you missing from your current problem statement?",
  "Problem Statement Parts Zero Shot":
    "What subactivities need to be executed in order to solve your problem?",
  "Groupify Few Shot": {
    Community: "What ideas could be inspired by how communities work?",
    Market: "What ideas could be inspired by how a market works?",
    Democracy:
      "What ideas could be inspired by how democratic decision-making works?",
  },
  "Technify Zero Shot":
    "What ideas could emerge by using specific technologies?",
  "Creative Matrix Zero Shot":
    "What ideas could be emerge by combining some degree of technologies and some degree of communities?",
  "Better Problem Statement Reformulation Zero Shot":
    "What are alternative ways to formulate your problem statement?",
  "Corpus D": "Here are some wild (possibly fictitious) ideas(s):",
  "Tom's Book + Corpus C": "Here are some wild (possibly fictitious) idea(s):",
  "Supermind Design Library":
    "Here is an idea from a database of innovative Superminds",
};

const labelMapping = {
  "general.zoom_in_types":
    "Zoom In - Types",
  "general.zoom_out_types":
    "Zoom Out - Types",
  "general.zoom_in_parts":
    "Zoom In - Parts",
  "general.zoom_out_parts":
    "Zoom Out - Parts",
  "general.analogize":
    "Analogize",
  "general.what_am_i_missing":
    "What Am I Missing",
  "general.problem_statement_parts":
    "Problem Statement Parts",
  "general.better_problem_statement_reformulation":
    "Better Problem Statement Reformulation",
  "general.creative_matrix":
    "Creative Matrix",
  "general.sdl_search":
    "Supermind Design Library",
  "general.zoom_in_fewshot":
    "Zoom In Fewshot",
  "general.zoom_out_fewshot":
    "Zoom Out Fewshot",
  "technify.zeroshot":
    "Technify",
  "groupify.market_fewshot":
    "Groupify - Market",
  "groupify.community_fewshot":
    "Groupify - Community",
  "groupify.democracy_fewshot":
    "Groupify - Democracy",
  "groupify.ecosystem_fewshot":
    "Groupify - Ecosystem",
  "cognify.create_zeroshot":
    "Cognify - Create",
  "cognify.decide_zeroshot":
    "Cognify - Decide",
  "cognify.learn_zeroshot":
    "Cognify - Learn",
  "cognify.remember_zeroshot":
    "Cognify - Remember",
  "cognify.sense_zeroshot":
    "Cognify - Sense",
};

export {
  // supermindModelsDescription,
  // moreChoicesModels,
  // supermindModels,
  // thinkAboutItModels,
  exploreProblemMoves,
  // findSolutionsModels,
  exploreSolutionsMoves,
  modelBlurbs,
  advancedMoves,
  labelMapping,
};
