import { API } from "aws-amplify";

// graphql imports
import * as mutations from "../graphql/mutations";

// constant imports
import technifyTechnologies from "../constants/supermind_technify_technologies";
import { modelBlurbs } from "../constants/supermind_models";

// package imports
import { v4 as uuid } from "uuid";

/**
 * This function cleans up the default response dictionary from Supermind Ideator
 * API to be in the right form for the schema defined for the Response object in
 * AWS DynamoDB
 *
 * @function populateDefaultResponseData
 * @param {object} response response is the default response from Supermind
 * Ideator API. It's in the follow form.
 *
 * {
 *   move: "...",
 *   problem: "...",
 *   technologies: ...,
 *   temperature: ...,
 *   model: "...",
 *   max_tokens: ...,
 *   idea: "...",
 *   ideaFrame: "...",
 * }
 * @returns {null} function directly modifies response, so function returns nothing
 */
const populateDefaultResponseData = (response) => {
  // default id for response
  response["id"] = uuid();
  // replace idea with response
  if(response.relevance != null){
    response["response"] = response.idea + "\n\nWhy this idea?\n" + response.relevance;
    delete response.relevance;
    delete response.idea;
  }else{
    response["response"] = response.idea ;
    delete response.idea;
  }
  // default bookmarked to false
  response["bookmarked"] = false;
  // defult preference to neutral
  response["preference"] = "neutral";
  // default childrenResponses to empty array
  response["childrenResponses"] = [];
  // replace technologies with technify
  response["technify"] = response.technologies;
  delete response.technologies;
  // remove max_tokens entry
  delete response.max_tokens;
  // return
  return;
};

const parseTechnify = (arr) => {
  var out = "";
  if (typeof arr == null){
    return out
  }
  else{
    if (Object.hasOwn(arr, 'length')){
      for (let i = 0; i < arr.length; i++) {
        out += (i + 1).toString();
        out += ". " + arr[i];
        if (i !== arr.length - 1) out += " ";
      }
    }
  }
  return out;
};

const requestResponses = async ({ model, parameters, openaiApiKey }) => {
  // console.log(model, parameters, openaiApiKey);
  const queryStringParameters = {
    queryStringParameters: { model, parameters, openaiApiKey },
  };
  try {
    return await API.get(
      "endpoints",
      "/supermind-ideation",
      queryStringParameters
    );
  } catch (error) {
    throw error;
  }
};

const updateTokensAmount = async (owner, tokens) => {
  try {
    await API.graphql({
      query: mutations.updateTokens,
      variables: {
        input: {
          owner,
          tokens,
        },
      },
    });
  } catch (error) {
    console.log(`Error updating tokens amount: ${error}`);
  }
};

const getRandomTechnologies = (amount) => {
  var arr = technifyTechnologies,
    n = amount;
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

const getModelBlurb = ({ model, parameters }) => {
  if (parameters.groupify){
    return modelBlurbs[model][parameters.groupify];
  }
  else{
    return modelBlurbs[model];
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getRandomElementInArray = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

const removeIWantToDupIfAny = (problem) => {
  const regexp = /i want to(.*)/gi;
  // console.log(problem)
  var matches = [...problem.matchAll(regexp)];
  if (matches[0]) {
    return "I want to " + matches[0].at(-1).trim();
  } else {
    return "I want to " + problem.trim();
  }
};

export {
  parseTechnify,
  requestResponses,
  updateTokensAmount,
  getRandomTechnologies,
  getModelBlurb,
  capitalizeFirstLetter,
  getRandomElementInArray,
  removeIWantToDupIfAny,
  populateDefaultResponseData,
};
