const supermindIdeatorAPIMovesLabelMapping = {
  "technify.zeroshot": "Technify",
  "technify.v1": null,
  "groupify.ecosystem_fewshot": "Ecosystem",
  "groupify.market_fewshot": "Market",
  "groupify.community_fewshot": "Community",
  "groupify.democracy_fewshot": "Democracy",
  "general.creative_matrix": "Creative Matrix",
  "general.zoom_in_fewshot": "Zoom In",
  "general.zoom_in_parts": "Zoom In - Parts",
  "general.sdl_search": "Supermind Design Library",
  "general.zoom_out_types": "Zoom Out - Types",
  "general.what_am_i_missing": "What Am I Missing",
  "general.analogize": "Analogize",
  "general.zoom_out_parts": "Zoom Out - Parts",
  "general.better_problem_statement_reformulation":
    "Better Problem Statement Reformulation",
  "general.problem_statement_parts": "Problem Statement Parts",
  "general.zoom_out_fewshot": "Zoom Out",
  "general.zoom_in_types": "Zoom In - Types",
  "chain.cognify_sense": "Sense",
  "cognify.learn_zeroshot": "Learn",
  "cognify.decide_zeroshot": "Decide",
  "cognify.create_zeroshot": "Create",
  "cognify.sense_zeroshot": "Sense",
  "cognify.remember_zeroshot": "Remember",
};

export default supermindIdeatorAPIMovesLabelMapping;
