const accessibleModels = {
  // public: new Set([
  //   "Zoom Out Few Shot",
  //   "Zoom In Few Shot",
  //   "Analogize Zero Shot",
  //   "Groupify Few Shot",
  //   "Technify Zero Shot",
  //   "Creative Matrix Zero Shot",
  //   "What Am I Missing Zero Shot",
  //   "Better Problem Statement Reformulation Zero Shot",
  //   "Problem Statement Parts Zero Shot",
  //   // "Supermind Design Library",
  // ]),
  // cci: new Set([
  //   "Zoom Out Few Shot",
  //   "Zoom In Few Shot",
  //   "Analogize Zero Shot",
  //   "Groupify Few Shot",
  //   "Technify Zero Shot",
  //   "Corpus D",
  //   "Creative Matrix Zero Shot",
  //   "What Am I Missing Zero Shot",
  //   "Better Problem Statement Reformulation Zero Shot",
  //   "Problem Statement Parts Zero Shot",
  //   // "Supermind Design Library",
  // ]),
  public: new Set([
    "general.zoom_out_fewshot",
    "general.zoom_in_fewshot",
    "general.analogize",
    "groupify.ecosystem_fewshot",
    "groupify.market_fewshot",
    "groupify.community_fewshot",
    "groupify.democracy_fewshot",
    "technify.zeroshot",
    "general.creative_matrix",
    "general.sdl_search",
    "general.what_am_i_missing",
    "general.better_problem_statement_reformulation",
    "general.problem_statement_parts",
  ]),
  cci: new Set([
    "general.zoom_out_fewshot",
    "general.zoom_in_fewshot",
    "general.analogize",
    "groupify.ecosystem_fewshot",
    "groupify.market_fewshot",
    "groupify.community_fewshot",
    "groupify.democracy_fewshot",
    "technify.zeroshot",
    "general.creative_matrix",
    "general.sdl_search",
    "general.what_am_i_missing",
    "general.better_problem_statement_reformulation",
    "general.problem_statement_parts",
  ]),
};

export default accessibleModels;
