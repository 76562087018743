import { useState } from "react";

// package imports
import { API, Auth } from "aws-amplify";
import axios from "axios";

// react redux imports
import { useSelector, useDispatch } from "react-redux";
import { addChildrenResponse } from "../features/responses/responsesSlice";
import { incrementTokens } from "../features/tokens/tokensSlice";

// graphql imports
import * as mutations from "../graphql/mutations";

// util imports
import {
  updateTokensAmount,
  populateDefaultResponseData,
} from "../functions/utils";

const getAccessToken = async () => {
  var currAuthUser = await Auth.currentAuthenticatedUser();
  var username = currAuthUser.signInUserSession.idToken.payload.email;
  var accessToken = currAuthUser.signInUserSession.accessToken.jwtToken;
  return { username, access_token: accessToken };
};

const useResponse = ({ id: responseId, responseGroupIndex, responseIndex }) => {
  const dispatch = useDispatch();
  const {
    user: { value: user, isCCIResearcher: userIsCCIResearcher },
    tokens: { value: tokens, maxTokens },
    apiKey: { value: openaiApiKey },
  } = useSelector((store) => store);
  const [loading, setLoading] = useState(false);

  /**
   * createChildrenResponse extracts all the necessary information from the generated children response,
   * and parses them accordinly (i.e., JSON stringify technify array object if it exists, casts temperature
   * as float, etc...) and then creates the children response in DynamoDB through GraphQL.
   *
   * @async
   * @function createChildrenResponse
   * @param {object} childrenResponse
   * childrenResponse is in the following format:
   * {
   *   id (string, always exists): "...",
   *   problem (string, always exists): "...",
   *   prompt (string, always exists): "...",
   *   temperature (string, always exists): "...",
   *   model (string, always exists): "...",
   *   preference (string, ): "...",
   *   bookmarked (boolean, always exists): ...,
   *   childrenResponses (array object, always exists): [...],
   *   basic (string, only exists if response used basic): "...",
   *   groupify (string, only exists if response used groupify): "...",
   *   cognify (string, only exists if response used cognify): "...",
   *   technify (array object, only if response used technify): [...],
   * }
   */
  const createChildrenResponse = async (childrenResponse) => {
    // extracts technify (for JSON stringify later before creating the new childrenResponse),
    // temperature (for casting to float later), prompt (this is the parsed prompt from backend)
    // and will not be included in childrenResponse because it's not a defined part of the schema,
    // and childrenResponses (same reason as prompt, also the children will not have more children responses)
    const {
      technify,
      temperature,
      prompt,
      childrenResponses,
      ...remainingChildrenResponseParams
    } = {
      ...childrenResponse,
    };
    const childrenResponseDetails = {
      ...remainingChildrenResponseParams,
      ...(technify && {
        technify: JSON.stringify(technify),
      }),
      temperature: parseFloat(temperature),
      responseChildrenResponsesId: responseId,
    };
    await API.graphql({
      query: mutations.createChildrenResponse,
      variables: { input: childrenResponseDetails },
    });
  };

  /**
   * onRunAgain generates a childrenResponse tied to this current parent response
   * with the same parameters that generated the parent response.
   *
   * @async
   * @function onRunAgain
   * @param {object} param0 param0 is all the necessary information from the
   * parent response. (i.e. problem (always exists), basic (if exists), groupify
   * (if exists), cognify (if exists), temperature (always exists), tehcnify (if exists),
   * model (awlays exists))
   */
  const onRunAgain = async ({
    problem,
    basic,
    groupify,
    cognify,
    temperature,
    technify,
    model,
    move,
  }) => {
    // check if user exceeds maxTokens
    if (maxTokens != null && tokens >= maxTokens)
      return alert(
        "You have used your generation allotment. Please contact ideator@mit.edu if you wish to generate more."
      );

    var credentials = userIsCCIResearcher
    ? await getAccessToken()
    : { api_key: openaiApiKey };

    setLoading((prevState) => !prevState);
    document.body.style.cursor = "wait";
    var tempTokens = tokens;

    const data = {
      ...credentials,
      problem,
      ...(technify.length > 0 && { technologies: technify }),
    };

    try {
      // const { data: childrenResponse } = await requestResponses({
      //   model,
      //   parameters: JSON.stringify({
      //     problem,
      //     basic,
      //     groupify,
      //     cognify,
      //     temperature,
      //     technify,
      //   }),
      // });
      const res = await axios.post(
        `https://ideator.mit.edu/api/v1/assistant/${move}`,
        data
      );
      var childrenResponse = { ...res.data.request, ...res.data.response };
      populateDefaultResponseData(childrenResponse);
      await createChildrenResponse(childrenResponse);
      dispatch(
        addChildrenResponse({
          responseGroupIndex,
          responseIndex,
          childrenResponse,
        })
      );
      await updateTokens(tempTokens + 1);
    } catch (error) {
      console.log(error);
    }
    setLoading((prevState) => !prevState);
    document.body.style.cursor = "default";
  };

  /**
   * updates the tokens for the authenticated user to a new amount
   * @param {int} amount the new tokens amount to be updated to
   */
  const updateTokens = async (amount) => {
    await updateTokensAmount(user.username, amount);
    dispatch(incrementTokens(1));
  };

  return {
    loading,
    onRunAgain,
  };
};

export default useResponse;
