import { useState, useLayoutEffect, useRef } from "react";

// package imports
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// hook imports
import useResponse from "../hooks/useResponse";
import useResponseUserSelections from "../hooks/useResponseUserSelections";

// component imports
import Label from "./Label";

// function imports
import {
  parseTechnify,
  // getModelBlurb,
  capitalizeFirstLetter,
} from "../functions/utils";

// constant imports
import temperatureMapping from "../constants/qualitative_temperature_mapping";
import supermindIdeatorAPIMovesLabelMapping from "../constants/supermind_ideator_api_moves_label_mapping";

const ResponseLabels = (props) => {
  var {
    showModel,
    model,
    move,
    // basic,
    // groupify,
    // cognify,
    technify,
    // solutionType,
    // explorationType,
    // semanticSimilarity,
    temperature,
    helperText,
    // responseGroupType,
  } = props;
  return (
    <>
      {showModel && model && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Model: " : ""}${model}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )}
      {/* {basic && basic !== "None" && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Basic: " : ""}${basic}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )} */}
      {/* {groupify && groupify !== "None" && (
        <div>
          <Label
            backgroundColor="bg-gray-200/25"
            text={`${helperText ? "Groupify: " : ""}${groupify}${
              responseGroupType === "Explore Solutions" &&
              model === "Groupify Few Shot" &&
              ["Market", "Community"].includes(groupify)
                ? " 1"
                : ""
            }${
              responseGroupType === "Explore Solutions" &&
              model === "Corpus D" &&
              ["Market", "Community"].includes(groupify)
                ? " 2"
                : ""
            }`}
            textColor="text-black/75"
            tailwindStyle="mr-2"
          />
        </div>
      )} */}
      {/* {cognify && cognify !== "None" && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Cognify: " : ""}${cognify}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )} */}
      {typeof move != "undefined" && move.includes("technify") && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Technify" : ""}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )}
      {typeof supermindIdeatorAPIMovesLabelMapping[move] != "undefined" && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Technify: " : ""}${
            supermindIdeatorAPIMovesLabelMapping[move]
          }`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )}
      {/* {solutionType && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Solution Type: " : ""} ${solutionType}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )} */}
      {/* {explorationType && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Exploration Type: " : ""}${explorationType}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )} */}
      {/* {semanticSimilarity && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`Semantic Similarity: ${semanticSimilarity}`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )} */}
      {temperature && (
        <Label
          backgroundColor="bg-gray-200/25"
          text={`${helperText ? "Creativity: " : ""}${
            temperatureMapping.int_to_val[temperature]
          }`}
          textColor="text-black/75"
          tailwindStyle="mr-2"
        />
      )}
    </>
  );
};

const ResponseUserSelections = ({ responseId, type }) => {
  const { liked, disliked, bookmarked, onLike, onDislike, onBookmark } =
    useResponseUserSelections({ responseId, type });

  return (
    <div className="flex gap-2">
      <div
        className={`flex justify-center items-center cursor-pointer transition-all ${
          liked ? "text-green-400" : "text-gray-400/40 hover:text-green-600/50"
        }`}
        onClick={onLike}
      >
        <ThumbUpIcon sx={{ fontSize: 22 }} />
      </div>
      <div
        className={`flex justify-center items-center cursor-pointer transition-all ${
          disliked ? "text-red-400" : "text-gray-400/40 hover:text-red-600/50"
        }`}
        onClick={onDislike}
      >
        <ThumbDownIcon sx={{ fontSize: 22 }} />
      </div>
      <div
        className={`flex justify-center items-center cursor-pointer transition-all ${
          bookmarked
            ? "text-blue-400"
            : "text-gray-400/40 hover:text-blue-600/50"
        }`}
        onClick={onBookmark}
      >
        <BookmarkIcon sx={{ fontSize: 22 }} />
      </div>
    </div>
  );
};

const ChildResponse = ({
  index,
  lastChildResponse,
  newestResponseGroup,
  lastResponseInResponseGroup,
  responseNumericalLabel,
  id,
  response,
}) => {
  const [truncateChildResponseState, setTruncateChildResponseState] =
    useState(true);
  const [largeChildResponse, setLargeChildResponse] = useState(false);
  const childResponseDivRef = useRef(null);

  useLayoutEffect(() => {
    if (childResponseDivRef.current.clientHeight > 120)
      setLargeChildResponse(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      key={index}
      className={`relative bg-white p-container ml-8 mb-0.5 ${
        newestResponseGroup ? "" : ""
      } ${
        lastChildResponse && lastResponseInResponseGroup
          ? "rounded-br-lg rounded-bl-lg"
          : ""
      }`}
    >
      <div className="flex justify-between items-center mb-2">
        <Label
          backgroundColor="bg-gray-200/25"
          textColor="text-black/75"
          tailwindStyle="mr-2 font-semibold text-sm"
          text={`Idea ${responseNumericalLabel}.${index + 1}`}
        />
        <ResponseUserSelections responseId={id} type="children" />
      </div>
      <div
        className={`mb-3 relative ${
          truncateChildResponseState && largeChildResponse ? "max-h-24" : ""
        }`}
        ref={childResponseDivRef}
      >
        <p
          className="whitespace-pre-wrap overflow-hidden"
          style={
            truncateChildResponseState && largeChildResponse
              ? {
                  display: "-webkit-box",
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: "vertical",
                }
              : null
          }
        >
          {capitalizeFirstLetter(response)}
        </p>
        {truncateChildResponseState && largeChildResponse && (
          <div
            className="absolute w-full h-24 bg-gradient-to-b from-transparent to-white bottom-0 left-0"
            onClick={() =>
              setTruncateChildResponseState((prevState) => !prevState)
            }
            style={{ cursor: "pointer" }}
          />
        )}
        {largeChildResponse && (
          <div
            className="w-full grid place-items-center"
            onClick={() =>
              setTruncateChildResponseState((prevState) => !prevState)
            }
          >
            {!truncateChildResponseState ? (
              <ExpandLessIcon sx={{ fontSize: 16, cursor: "pointer" }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: 16, cursor: "pointer" }} />
            )}
          </div>
        )}
      </div>
      {/* <div className="h-0.5 w-6 bg-white absolute top-1/2 right-[100%] translate-y-[-50%] translate-x-[-0.6rem] rounded-full" />
              <div className="h-6 w-0.5 bg-white absolute top-1/2 right-[100%] translate-y-[-100%] translate-x-[-2rem] rounded-full" /> */}
    </div>
  );
};

const Response = (props) => {
  const {
    id,
    // problem,
    model,
    move,
    basic,
    groupify,
    cognify,
    technify,
    temperature,
    solutionType,
    explorationType,
    semanticSimilarity,
    response,
    ideaFrame,
    // bookmarked,
    // preference,
    childrenResponses,
    ideationNumber,
    newestResponseGroup,
    lastResponseInResponseGroup,
    responseGroupIndex,
    responseIndex,
    generating,
    responseGroupType,
  } = props;
  const { loading, onRunAgain } = useResponse({
    id,
    responseGroupIndex,
    responseIndex,
  });

  // eslint-disable-next-line
  const [showDetails, setShowDetails] = useState(false); // showDetails currently unused
  const [showChildResponses, setShowChildResponses] = useState(true);
  const [largeResponse, setLargeResponse] = useState(false);
  const [truncateResponseState, setTruncateResponseState] = useState(true);
  const responseDivRef = useRef(null);

  useLayoutEffect(() => {
    if (responseDivRef.current.clientHeight > 120) setLargeResponse(true);
    // eslint-disable-next-line
  }, []);

  const responseNumericalLabel =
    (ideationNumber + 1).toString() + "." + (responseIndex + 1).toString();

  return (
    <>
      <div
        className={`bg-white p-container overflow-auto mb-0.5 ${
          newestResponseGroup ? "" : ""
        } ${
          !generating &&
          lastResponseInResponseGroup &&
          (!showChildResponses || childrenResponses.length === 0)
            ? "rounded-bl-lg rounded-br-lg"
            : ""
        }`}
      >
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center flex-1">
            <p className="font-bold mr-2">
              {/* {getModelBlurb({
                model,
                parameters: { basic, groupify, cognify, technify },
              })} */}
              {ideaFrame}
            </p>
            <div className="flex items-center">
              <Label
                backgroundColor="bg-gray-200/25"
                textColor="text-black/75"
                tailwindStyle="mr-2"
                text={`Idea ${responseNumericalLabel}`}
              />
              <ResponseLabels
                showModel={false}
                model={model}
                move={move}
                responseGroupType={responseGroupType}
                basic={basic}
                groupify={groupify}
                cognify={cognify}
                technify={technify}
                solutionType={solutionType}
                explorationType={explorationType}
                helperText={false}
              />
            </div>
          </div>
          <ResponseUserSelections responseId={id} type="parent" />
        </div>
        <div
          className={`mb-3 relative ${
            largeResponse && truncateResponseState ? "max-h-24" : ""
          }`}
          ref={responseDivRef}
        >
          <p
            className="whitespace-pre-wrap overflow-hidden"
            style={
              largeResponse && truncateResponseState
                ? {
                    display: "-webkit-box",
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: "vertical",
                  }
                : null
            }
          >
            {capitalizeFirstLetter(response)}
          </p>
          {largeResponse && truncateResponseState && (
            <div
              className="absolute w-full h-24 bg-gradient-to-b from-transparent to-white bottom-0 left-0"
              onClick={() =>
                setTruncateResponseState((prevState) => !prevState)
              }
              style={{ cursor: "pointer" }}
            />
          )}
          {largeResponse && (
            <div
              className="w-full grid place-items-center"
              onClick={() =>
                setTruncateResponseState((prevState) => !prevState)
              }
            >
              {!truncateResponseState ? (
                <ExpandLessIcon sx={{ fontSize: 16, cursor: "pointer" }} />
              ) : (
                <ExpandMoreIcon sx={{ fontSize: 16, cursor: "pointer" }} />
              )}
            </div>
          )}
        </div>
        {showDetails && (
          <div className="flex flex-wrap mb-3">
            <ResponseLabels
              showModel={true}
              model={model}
              basic={basic}
              groupify={groupify}
              cognify={cognify}
              technify={technify}
              explorationType={explorationType}
              solutionType={solutionType}
              semanticSimilarity={semanticSimilarity}
              temperature={temperature}
              helperText={true}
            />
          </div>
        )}
        <div className="flex justify-end items-center">
          {/* <button
            className={`transition-all cursor-pointer select-none py-1 px-2 border-[1px] rounded-md ${
              newestResponseGroup ? "" : ""
            } ${
              showDetails
                ? "bg-gray-600/75 text-white"
                : "hover:bg-gray-600/75 hover:text-white"
            }`}
            onClick={() => setShowDetails((prevState) => !prevState)}
          >
            {showDetails ? "Hide" : "Show"} Details
          </button> */}
          <div className="flex items-center">
            {/* <button
              className={`transition-all cursor-pointer select-none py-1 px-2 mr-2 border-[1px] rounded-md ${
                newestResponseGroup ? "" : ""
              } ${
                showDetails
                  ? "bg-gray-600/75 text-white"
                  : "hover:bg-gray-600/75 hover:text-white"
              }`}
              onClick={() => setShowDetails((prevState) => !prevState)}
            >
              {showDetails ? "Hide" : "Show"} Details
            </button> */}
            {childrenResponses?.length > 0 && (
              <button
                className={`transition-all cursor-pointer select-none py-1 px-2 border-[1px] rounded-md mr-2 ${
                  newestResponseGroup ? "" : ""
                } ${
                  showChildResponses
                    ? "bg-gray-600/75 text-white"
                    : "hover:bg-gray-600/75 hover:text-white"
                }`}
                onClick={() => setShowChildResponses((prevState) => !prevState)}
              >
                {showChildResponses ? "Hide" : "Show"}{" "}
                {childrenResponses.length} Sub Response
                {childrenResponses.length > 1 && "s"}
              </button>
            )}
            {model !== "Supermind Design Library" && (
              <button
                className={`hover:bg-blue-600/50 hover:text-white transition-all cursor-pointer select-none py-1 px-2 border-[1px] rounded-md ${
                  (loading || generating) && "opacity-25 pointer-events-none"
                } ${newestResponseGroup ? "" : ""}`}
                onClick={() => onRunAgain(props)}
              >
                Run This Again
              </button>
            )}
          </div>
        </div>
      </div>
      {childrenResponses?.length > 0 &&
        showChildResponses &&
        childrenResponses.map(({ response, id }, index) => {
          const lastChildResponse = index === childrenResponses.length - 1;
          return (
            <ChildResponse
              key={index}
              index={index}
              lastChildResponse={lastChildResponse}
              newestResponseGroup={newestResponseGroup}
              lastResponseInResponseGroup={lastResponseInResponseGroup}
              responseNumericalLabel={responseNumericalLabel}
              id={id}
              response={response}
            />

            // <div
            //   key={index}
            //   className={`relative bg-white p-container ml-8 mb-0.5 ${
            //     newestResponseGroup ? "" : ""
            //   } ${
            //     lastChildResponse && lastResponseInResponseGroup
            //       ? "rounded-br-lg rounded-bl-lg"
            //       : ""
            //   }`}
            // >
            //   <div className="flex justify-between items-center mb-2">
            //     <Label
            //       backgroundColor="bg-gray-200/25"
            //       textColor="text-black/75"
            //       tailwindStyle="mr-2 font-semibold text-sm"
            //       text={`Idea ${responseNumericalLabel}.${index + 1}`}
            //     />
            //     <ResponseUserSelections responseId={id} type="children" />
            //   </div>
            //   <p className="whitespace-pre-wrap">
            //     {capitalizeFirstLetter(response)}
            //   </p>
            //   {/* <div className="h-0.5 w-6 bg-white absolute top-1/2 right-[100%] translate-y-[-50%] translate-x-[-0.6rem] rounded-full" />
            //   <div className="h-6 w-0.5 bg-white absolute top-1/2 right-[100%] translate-y-[-100%] translate-x-[-2rem] rounded-full" /> */}
            // </div>
          );
        })}
    </>
  );
};

export default Response;
