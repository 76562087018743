import { useState, useEffect } from "react";

// graphql imports
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  createResponseGroup,
  addResponse,
} from "../features/responses/responsesSlice";
import { setApiKey } from "../features/apiKey/apiKeySlice";
import {
  incrementTokens,
  setTokensAmount,
  setMaxTokens,
} from "../features/tokens/tokensSlice";

// util imports
import {
  // requestResponses,
  // getRandomTechnologies,
  getRandomElementInArray,
  updateTokensAmount,
  populateDefaultResponseData,
} from "../functions/utils";

// package imports
import { API, Auth, graphqlOperation } from "aws-amplify";
import axios from "axios";

// constant imports
import accessibleModels from "../constants/supermind_accessible_models";
import {
  // moreChoicesModels,
  // thinkAboutItModels,
  // findSolutionsModels,
  exploreProblemMoves,
  exploreSolutionsMoves,
  advancedMoves,
} from "../constants/supermind_models";
// import {
//   groupifyMoves,
//   cognifyMoves,
// } from "../constants/supermind_design_moves";

const useGeneratePanel = ({ setLoading, responsesDivRef }) => {
  const dispatch = useDispatch();
  const {
    user: { isCCIResearcher: userIsCCIResearcher, value: user },
    apiKey: { value: apiKey },
    tokens: { value: tokens, maxTokens },
  } = useSelector((store) => store);

  const models = userIsCCIResearcher
    ? accessibleModels.cci
    : accessibleModels.public;

  const [parameters, setParameters] = useState({
    openaiApiKey: apiKey,
    problem: "",
    basic: "None",
    groupify: "None",
    cognify: "None",
    technify: new Set(),
    temperature: 1.0,
  });

  useEffect(() => {
    fetchTokens();
    // eslint-disable-next-line
  }, []);

  /**
   * fetchTokens fetches tokens data for the authenticated user. Data contains tokens (
   * the number of responses user has generated, each token is associated with one model
   * response) and maxTokens (the maxTokens allowed for the user, if they exceed this,
   * they can't generate more responses). Also checks whether user has an associated maxTokens,
   * if not, update their entry with 250 default maxTokens. Dispatch the tokens count and
   * maxTokens count to redux state.
   *
   * @async
   * @function fetchTokens
   * @return {undefined}
   */
  const fetchTokens = async () => {
    try {
      const res = await API.graphql({
        query: queries.getTokens,
        variables: {
          owner: user.username,
        },
      });
      // console.log(res.data.getTokens);
      // Create entry for user's token count in Tokens DB if not created yet
      if (res.data.getTokens === null) {
        API.graphql(
          graphqlOperation(mutations.createTokens, {
            input: { owner: user.username, tokens: 0, maxTokens: 250 },
          })
        );
        dispatch(setTokensAmount(0));
      } else {
        // if user does not have a maxTokens associated with them, update
        // their data with maxTokens of 250 (default) and set redux state for
        // maxTokens to 250, else set the maxTokens to whatever is in DB
        if (res.data.getTokens.maxTokens === null) {
          API.graphql(
            graphqlOperation(mutations.updateTokens, {
              input: { owner: user.username, maxTokens: 250 },
            })
          );
          dispatch(setMaxTokens(250));
        } else dispatch(setMaxTokens(res.data.getTokens.maxTokens));
        dispatch(setTokensAmount(res.data.getTokens.tokens));
      }
    } catch (error) {
      // console.log(`Error fetching tokens: ${error}`);
    }
  };

  const updateTokens = async (amount) => {
    await updateTokensAmount(user.username, amount);
    dispatch(incrementTokens(1));
  };

  const scrollResponsesDivToTop = () => {
    responsesDivRef?.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateParameter = (param, value) => {
    // update apiKey
    if (param === "openaiApiKey") dispatch(setApiKey(value));

    // Update technify set
    if (param === "technify") {
      if (value === "None") value = new Set();
      else if (value === "3 Random Technologies")
        value = new Set(["3 Random Technologies"]);
      else {
        var technifyCopy = new Set([...parameters.technify]);
        technifyCopy.delete("3 Random Technologies");
        if (technifyCopy.has(value)) technifyCopy.delete(value);
        else technifyCopy.add(value);
        value = technifyCopy;
      }
    }
    // Reset basic field if user using supermind, and vice versa (they are mutually exclusive)
    var reset = {};
    if (param === "basic")
      reset = {
        groupify: "None",
        cognify: "None",
        technify: new Set(),
      };
    else if (["groupify", "cognify", "technify"].includes(param))
      reset = { basic: "None" };
    // Update the parameters accordingly
    setParameters((prevState) => ({
      ...prevState,
      ...reset,
      [param]: value,
    }));
  };

  /**
   * resetParameters resets the parameters to default inputs
   *
   * @function resetParameters
   */
  const resetParameters = () => {
    setParameters((prevState) => ({
      ...prevState,
      problem: "",
      basic: "None",
      groupify: "None",
      cognify: "None",
      technify: new Set(),
      temperature: 1.0,
    }));
  };

  // /**
  //  * getMoreChoicesModels gets the appropriate list of models to run based on the
  //  * options that the users selected.
  //  *
  //  * @function getMoreChoicesModels
  //  * @returns {object} array object containing models and parameters
  //  * [ arrays of model_name and parameters, i.e.
  //  *  ["Technify Zero Shot", { problem: "...", temperature: ..., technify: [...] }],
  //  *  ...
  //  * ]
  //  */
  // const getMoreChoicesModels = () => {
  //   const addAdditionalParam = (baseModel) => {
  //     const { problem, temperature, technify } = parameters;
  //     baseModel[1]["problem"] = problem;
  //     baseModel[1]["temperature"] = temperature;
  //     if (technify.size !== 0)
  //       if (technify.has("3 Random Technologies"))
  //         baseModel[1]["technify"] = getRandomTechnologies(3);
  //       else baseModel[1]["technify"] = Array.from(technify);
  //     return baseModel;
  //   };
  //   const out = [];
  //   const { basic, groupify, cognify, technify } = parameters;
  //   // handle basic
  //   if (basic !== "None") {
  //     if (basic === "Any") {
  //       const randomBasicMove = getRandomElementInArray(
  //         Object.keys(moreChoicesModels.basic)
  //       );
  //       out.push(addAdditionalParam(moreChoicesModels.basic[randomBasicMove]));
  //     } else out.push(addAdditionalParam(moreChoicesModels.basic[basic]));
  //   } else {
  //     // groupify model logic
  //     var tempCorpusDParams = { groupify, cognify };
  //     if (!["Hierarchy", "None"].includes(groupify)) {
  //       if (groupify === "Any") {
  //         const randomGroupifyMove = getRandomElementInArray(groupifyMoves);
  //         if (randomGroupifyMove !== "Hierarchy")
  //           out.push(
  //             addAdditionalParam(
  //               moreChoicesModels.supermind.groupify[randomGroupifyMove]
  //             )
  //           );
  //         out.push(
  //           addAdditionalParam([
  //             "Corpus D",
  //             { groupify: randomGroupifyMove, cognify: "None" },
  //           ])
  //         );
  //         tempCorpusDParams.groupify = randomGroupifyMove;
  //       } else {
  //         out.push(
  //           addAdditionalParam(moreChoicesModels.supermind.groupify[groupify])
  //         );
  //         out.push(
  //           addAdditionalParam(["Corpus D", { groupify, cognify: "None" }])
  //         );
  //       }
  //     }
  //     if (groupify === "Hierarchy")
  //       out.push(
  //         addAdditionalParam(["Corpus D", { groupify, cognify: "None" }])
  //       );
  //     // cognify model logic
  //     if (cognify === "Any") {
  //       const randomCognifyMove = getRandomElementInArray(cognifyMoves);
  //       tempCorpusDParams.cognify = randomCognifyMove;
  //     }
  //     if (cognify !== "None")
  //       out.push(addAdditionalParam(["Corpus D", tempCorpusDParams]));
  //     // technify model logic
  //     if (technify.size !== 0)
  //       out.push(addAdditionalParam(moreChoicesModels.supermind.technify));
  //   }
  //   return out.filter((model) => models.has(model[0]));
  // };

  /**
   * This function returns an array of the appropriate move endpoints to the Supermind
   * Ideator API based on what the users has selected in the Advanced section.
   *
   * Example:
   * if users selected multiple technologies, hierarchy for groupify, and sense for cognify,
   * the returned array should be ["technify.v1", "cognify.sense_finetune", "cognify.sense_zeroshot",
   * "groupify.hierarchy_finetune"]
   *
   * @function getAdvancedMoves
   * @returns {array} list of move endpoints to access the approprite move in the
   * Supermind Ideator API. URL: https://ideator.mit.edu/api/v1/run/{moveEndpoint}
   */
  const getAdvancedMoves = () => {
    // initialie output array, out
    var out = [];
    // get all the user selections in advanced tabs
    const { basic, groupify, cognify, technify } = parameters;
    // start with basic, if something is selected for basic moves
    if (basic !== "None") {
      // if basic is not any, add to out array the appropriate move endpoint based
      // on what is selected
      if (basic !== "Any") out.push(...advancedMoves.basic[basic]);
      // if any is selected, randomly a move endpoint out of all the basic
      else {
        const allBasicMoves = Object.values(advancedMoves.basic);
        out.push(...getRandomElementInArray(allBasicMoves));
      }
    }
    // if something is selected for groupify
    if (groupify !== "None") {
      // if groupify is not any, add to out array the appropriate move endpoints based
      // on what is selected
      if (groupify !== "Any") out.push(...advancedMoves.groupify[groupify]);
      // if any is selected, randomly a move endpoint out of all the groupify moves
      else {
        const allGroupifyMoves = Object.values(advancedMoves.groupify);
        out.push(...getRandomElementInArray(allGroupifyMoves));
      }
    }
    // if something is selected for cognify
    if (cognify !== "None") {
      // if cognify is not any, add to out array the appropriate move endpoints based
      // on what is selected
      if (cognify !== "Any") out.push(...advancedMoves.cognify[cognify]);
      // if any is selected, randomly a move endpoint out of all the cognify moves
      else {
        const allCognifyMoves = Object.values(advancedMoves.cognify);
        out.push(...getRandomElementInArray(allCognifyMoves));
      }
    }
    // if something is selected for technify
    if (technify.size !== 0) {
      // if user selected "3 Random Technologies", add the appropriate move endpoint,
      // "technify.zeroshot" to out array
      if (technify.has("3 Random Technologies")) out.push("technify.zeroshot");
      // is user manually selected some technologies, add the appropriate move endpoint,
      // "technify.v1" to out array
      else out.push("technify.v1");
    }
    // return the out array
    return out;
  };

  /**
   * createResponse creates an entry in DynamoDB for a given response.
   *
   * @async
   * @function createResponse
   * @param {object} response dictionary object containing details of the response
   * response is of the following format:
   * {
   *   id (string): uuidv4 generated id,
   *   move (string): user specified move,
   *   problem (string): user input string,
   *   technologies (list str): user specified technologies,
   *   temperature (float): defaults to 1.0,
   *   model (string): defaults to "gpt-3.5-turbo",
   *   max_tokens (int): defaults to 384,
   *   bookmarked (boolean): false,
   *   preference (string): "neutral",
   *   childrenResponses (object): [],
   * }
   */
  const createResponse = async (response) => {
    /* get the remaining response params without technify (technify might 
    not be included), temperature (to parse into float), and prompt */
    const {
      technify,
      temperature,
      prompt,
      childrenResponses,
      ...remainingResponseParams
    } = {
      ...response,
    };
    const responseDetails = {
      ...remainingResponseParams,
      ...(technify && {
        technify: JSON.stringify(technify),
      }),
      temperature: parseFloat(temperature),
    };
    await API.graphql({
      query: mutations.createResponse,
      variables: {
        input: responseDetails,
      },
    });
  };

  /**
   * addAndCreateResponses adds a group of responses from a single call to redux state and
   * creates an entry in DynamoDB for each individual response through GraphQL
   *
   * @async
   * @function addAndCreateResponses
   * @param {string} problem problem statement associated for this group of responses
   * @param {object} data array of responses where each is a specific model call
   */
  const addAndCreateResponses = async (data) => {
    // console.log(data);
    dispatch(addResponse(data));
    // await createResponse(data);
  };

  /**
   * This function gets the username and access token of the currently authenticated user
   *
   * @async
   * @function getAccessToken
   * @returns {object} Returns a dictionary object mapping the username and access_token to the
   * current authenticated user
   */
  const getAccessToken = async () => {
    var currAuthUser = await Auth.currentAuthenticatedUser();
    var username = currAuthUser.signInUserSession.idToken.payload.email;
    var accessToken = currAuthUser.signInUserSession.accessToken.jwtToken;
    return { username, access_token: accessToken };
  };

  /**
   * The async function makes a request to openai api endpoint to request responses from GPT-3
   * for an associated problem statement and parameters. Upon recieving result from the api call,
   * add and create the responses to redux states and DynamoDB.
   * Result from the api call is in the form of the following:
   * [
   *  ...list of dictionary objects containing gpt-3 completion result for the problem statement,
   * i.e. {
   *    id (string): uuidv4 generated id,
   *    problem (string): "...",
   *    prompt (string): the parsed problem statement for specific model calls,
   *    response (string): response from gpt-3,
   *    basic (string, only generated if a model calls for it): "...",
   *    groupify (string, only generated if a model calls for it): "...",
   *    cognify (string, only generated if a model calls for it): "...",
   *    technify (string, only generated if a model calls for it): "...",
   *    temperature (float): "...",
   *    model (string): model used for this response,
   *    bookmarked (boolean): false,
   *    preference (string): "neutral",
   *    childrenResponses (object): [],
   *   }
   * ]
   *
   * @async
   * @function onSubmit
   */
  const onSubmit = async () => {
    const { problem, openaiApiKey } = parameters;

    // check if is cci researcher and if api key field is empty
    if (!userIsCCIResearcher && openaiApiKey.trim() === "")
      return alert("Please input your OpenAI API Key.");
    // check if problem statement is empty
    if (problem.trim() === "")
      return alert("Please input a problem statement before generating ideas.");
    // check if user exceeds maxTokens
    if (maxTokens != null && tokens >= maxTokens)
      return alert(
        "You have used your generation allotment. Please contact ideator@mit.edu if you wish to generate more."
      );

    // get all the advanced moves
    const allAdvancedMoves = getAdvancedMoves();

    // check if user selected parameters for more choices
    if (allAdvancedMoves.length === 0)
      return alert("Please select parameters before generating ideas.");

    // scroll the top of the responses display area if user is scrolled to the bottom
    // so they can see the newly generated idea
    scrollResponsesDivToTop();

    // set loading to true for animations and ux/ui experience
    setLoading(true);

    // initialize the response group for this generation in redux state
    dispatch(
      createResponseGroup({
        problem,
        responses: [],
        type: "More Choices",
        dateTime: new Date(),
      })
    );

    // get the current token count. to be updated after the idea is generated
    var tempTokens = tokens;

    // get the username and access_token for calling Supermind Ideator API if user is a valid CCI affiliate
    var credentials = userIsCCIResearcher
      ? await getAccessToken()
      : { api_key: openaiApiKey };

    // loop through all the advanced moves endpoints and make the call to Supermind Ideator API
    for (let m = 0; m < allAdvancedMoves.length; m++) {
      const moveEndpoint = allAdvancedMoves[m];
    // model is not set so API defaults to "gpt-3.5-turbo"
    // max_token is not set so API defaults to "384"
      const data = {
        ...credentials,
        problem,
        temperature: parameters.temperature,
        ...(moveEndpoint === "technify.v1" && {
          technologies: Array.from(parameters.technify),
        }),
      };
      try {
        // make the call to the Supermind Ideator API with the data
        const res = await axios.post(
          `https://ideator.mit.edu/api/v1/assistant/${moveEndpoint}`,
          data
        );
        // create a response dictionary with the API request and response data
        var response = { ...res.data.request, ...res.data.response,  runType: "More Choices"};
        // seralize and clean up the response dictionary
        populateDefaultResponseData(response);
        // add response to redux state for ui display and create entry in AWS DB for it
        await addAndCreateResponses(response);
        // update the token
        tempTokens += 1;
        updateTokens(tempTokens);
      } catch (error) {
        console.log(error);
        break;
      }
      // try {
      //   await new Promise((r) => setTimeout(r, 3000));
      //   const { data } = await requestResponses({
      //     model: moreChoicesModels[m][0],
      //     parameters: JSON.stringify(moreChoicesModels[m][1]),
      //     openaiApiKey: parameters.openaiApiKey,
      //   });
      //   await addAndCreateResponses(data);
      //   tempTokens += 1;
      //   updateTokens(tempTokens);
      // } catch (error) {
      //   if (error.message === "Request failed with status code 401")
      //     alert("Invalid OpenAI API Key");
      //   break;
      // }
    }
    // set loading to false to stop any loading animations
    setLoading(false);
  };

  // /**
  //  * getPresetModels returns a list of list of models and associated necessary parameters
  //  * (check @returns below for specifics). It goes through either thinkAboutItModels or findSolutionsModels
  //  * and adds problem to each model's parameters
  //  *
  //  * @function getPresetModels
  //  * @param {string} problem problem statement entered by the user
  //  * @param {string} preset preset can be either "Explore Problem" or "Explore Solutions"
  //  * @returns {object} returns array object in the following format:
  //  * [...list of list of models and necessary parameters, i.e.
  //  *     [
  //  *        "Groupify Few Shot",
  //  *        {
  //  *          problem (string): "...",
  //  *          groupify (string): "...",
  //  *          temperature (float): ...
  //  *        }
  //  *      ],
  //  *      [
  //  *        "Technify Zero Shot",
  //  *        {
  //  *          problem (string): "...",
  //  *          technify (object): ["Machine learning", "Edge computing"],
  //  *          temperature (float), ...
  //  *        }
  //  *      ]
  //  *    ]
  //  */
  // const getPresetModels = (problem, preset) => {
  //   var out = [];
  //   const presetModels =
  //     preset === "Explore Problem" ? thinkAboutItModels : findSolutionsModels;
  //   console.log(presetModels);
  //   for (let i = 0; i < presetModels.length; i++) {
  //     var [model, modelsParameters] = presetModels[i];
  //     if (models.has(model)) {
  //       modelsParameters["problem"] = problem;
  //       if (model.includes("Technify"))
  //         modelsParameters["technify"] = getRandomTechnologies(3);
  //       out.push([model, modelsParameters]);
  //     }
  //   }
  //   return out;
  // };

  /**
   * onPresetSubmit calls getPresetModels function to get the list of list of models
   * and associated parameters (check getPresetModels @return description to details)
   * and then sending to backend api to get the responses. After getting the responses,
   * it calls addAndCreateResponses function to add the responses to redux state and
   * creates entry in DynamoDB for each response.
   *
   * @async
   * @function onPresetSubmit
   * @param {string} preset preset is a string representing the preset that's clicked
   * (it can be either "Explore Problem or "Explore Solutions")
   */
  const onPresetSubmit = async (presetType) => {
    const { problem, openaiApiKey } = parameters;

    // Check if user is not cci researcher and api key field is empty
    if (!userIsCCIResearcher && openaiApiKey.trim() === "")
      return alert("Please input your OpenAI API Key.");
    // check if user inputted a problem
    if (problem.trim() === "")
      return alert("Please input a problem statement before generating ideas.");
    // check if user exceeds maxTokens
    if (maxTokens != null && tokens >= maxTokens)
      return alert(
        "You have used your generation allotment. Please contact ideator@mit.edu if you wish to generate more."
      );

    // scroll the top of the responses display area if user is scrolled to the bottom
    // so they can see the newly generated idea
    scrollResponsesDivToTop();

    // get all the appropriate preset moves based on the preset button clicked
    const presetMoves =
      presetType === "Explore Problem"
        ? exploreProblemMoves
        : exploreSolutionsMoves;

    // set loading to true for animations and ux/ui experience
    setLoading(true);

    // initialize the response group for this generation in redux state
    dispatch(
      createResponseGroup({
        problem,
        responses: [],
        type: presetType,
        dateTime: new Date(),
      })
    );

    // get the current token count. to be updated after the idea is generated
    var tempTokens = tokens;

    // get the username and access_token for calling Supermind Ideator API if user is a valid CCI affiliate
    var credentials = userIsCCIResearcher
      ? await getAccessToken()
      : { api_key: openaiApiKey };

    const data = {
      ...credentials,
      problem,
    };

    // loop through all the preset moves endpoints and make the call to Supermind Ideator API
    for (let m = 0; m < presetMoves.length; m++) {
      const moveEndpoint = presetMoves[m];

      try {
        // make the call to the Supermind Ideator API with the data
        const res = await axios.post(
          `https://ideator.mit.edu/api/v1/assistant/${moveEndpoint}`,
          data
        );
        // create a response dictionary with the API request and response data
        var response = { ...res.data.request, ...res.data.response, runType: presetType};
        // seralize and clean up the response dictionary
        populateDefaultResponseData(response);
        // add response to redux state for ui display and create entry in AWS DB for it
        await addAndCreateResponses(response);
        tempTokens += 1;
        updateTokens(tempTokens);
      } catch (error) {
        console.log(error);
        break;
      }
      // const [model, modelParameters] = presetMoves[m];
      // try {
      //   await new Promise((r) => setTimeout(r, 3000));
      //   const { data } = await requestResponses({
      //     model,
      //     parameters: JSON.stringify(modelParameters),
      //     openaiApiKey: parameters.openaiApiKey,
      //   });
      //   await addAndCreateResponses(data);
      //   tempTokens += 1;
      //   updateTokens(tempTokens);
      // } catch (error) {
      //   if (error.message === "Request failed with status code 401")
      //     alert("Invalid OpenAI API Key");
      //   break;
      // }
    }
    // set loading to false to stop any loading animations
    setLoading(false);
  };

  return {
    parameters,
    models,
    updateParameter,
    resetParameters,
    onSubmit,
    onPresetSubmit,
  };
};

export default useGeneratePanel;
